.hlg-adjust-table {
    margin-top: 2rem;
    width: 100%;
    border-collapse: collapse;
}

.hlg-adjust-table > thead > tr > th {
    border: 1px solid black;
    padding: 8px;
    background-color: #E5F6FD;
}

.hlg-adjust-table > tbody > tr > td {
    border: 1px solid black;
    padding: 8px;
}

.hlga-hour-row {
    width: 4rem;
    text-align: center;
}

.yellow-row {
    background-color: #FFF8E1;
}